<template>
  <div class="wrraper">
    <!-- Start Breadcrumb -->
    <Breadcrumb :items="items" />
    <!-- End Breadcrumb -->

    <transition-group name="fadeInUp" mode="in-out">
      <!-- Start Main Loader -->
      <MainLoader key="loader" v-if="loading" />
      <!-- End Main Loader -->

      <div key="main" v-else>
        <!-- Start Statistics Card-->
        <div class="order-details-page mb-5">
          <div class="order-status">
            <h2 class="title">
              {{ $t("titles.orderNumber") }} {{ `# ${allData.order_num}` }}
            </h2>
            <div class="status">
              <div class="date">
                <h5 v-if="allData.status_trans">
                  <span
                    v-if="allData.status == 'order_placed'"
                    class="status success bg-transparent"
                  >
                    {{ allData.status_trans }}
                  </span>
                  <span
                    v-else-if="
                      allData.status == 'delivered' ||
                      allData.status == 'arrived'
                    "
                    class="status success bg-transparent"
                  >
                    {{ allData.status_trans }}
                  </span>
                  <span
                    v-else-if="
                      allData.status == 'admin_reject' ||
                      allData.status == 'driver_cancel' ||
                      allData.status == 'client_cancel' ||
                      allData.status == 'admin_cancel' ||
                      allData.status == 'admin_cancel_after_delivered'
                    "
                    class="status canceled bg-transparent"
                  >
                    {{ allData.status_trans }}
                  </span>
                  <span v-else class="status pending bg-transparent">
                    {{ allData.status_trans }}
                  </span>
                </h5>
                <h5>
                  <span
                    class="status canceled bg-transparent"
                    v-if="allData.reject_reason"
                  >
                    {{ allData.reject_reason.title }}
                  </span>
                </h5>
              </div>
              <div class="action">
                <!-- <div class="action" v-if="!allData.have_item_out_of_stock"> -->
                <div
                  v-if="
                    allData.status == 'order_placed' ||
                    allData.status == 'progress' ||
                    allData.status == 'arrived' ||
                    allData.status == 'on_the_way'
                  "
                >
                  <!-- <span class="status success">
                    {{ item.status_trans }}
                  </span> -->

                  <div class="input_wrapper">
                    <v-select
                      :label="allData.status_trans"
                      solo
                      v-model="selectedStatus"
                      :items="orderStatusList(allData.status)"
                      @input="changeStatus($event)"
                    ></v-select>
                  </div>
                </div>
                <!-- <span
                  v-else-if="
                    allData.status == 'arrived' ||
                    allData.status == 'delivered' ||
                    allData.status == 'on_the_way'
                  "
                  class="status success bg-transparent"
                >
                  {{ allData.status_trans }}
                </span> -->
                <span
                  v-else-if="
                    allData.status == 'admin_reject' ||
                    allData.status == 'client_cancel' ||
                    allData.status == 'driver_cancel' ||
                    allData.status == 'admin_cancel' ||
                    allData.status == 'delivered' ||
                    allData.status == 'admin_cancel_after_delivered'
                  "
                  class="status canceled bg-transparent"
                >
                  <!-- {{ allData.status_trans }} -->
                </span>

                <div class="d-flex gap-3 justify-content-center" v-else>
                  <v-btn
                    class="px-10"
                    color="#1B5E20"
                    style="color: white"
                    @click="changeStatus($event, 'order_placed')"
                  >
                    {{ $t("accept") }}
                  </v-btn>
                  <v-btn
                    class="px-10"
                    color="#F44336 "
                    style="color: white"
                    @click="reject_reason_model = true"
                  >
                    {{ $t("reject") }}
                  </v-btn>
                </div>
              </div>
            </div>
          </div>
          <hr class="line" />
          <div class="details border-right-0">
            <div class="row">
              <div class="col-md-6 col-xxl-4">
                <div class="customer">
                  <div class="item">
                    <div class="icon">
                      <v-icon>fa-regular fa-user</v-icon>
                    </div>
                    <div class="info">
                      <h5 class="title">{{ $t("titles.userInfo") }}</h5>
                      <p>
                        {{ $t("titles.name") }}:
                        {{ allData.client.full_name }}
                      </p>

                      <p>
                        <span>{{ $t("titles.phone") }}</span>
                        <span>
                          {{ allData.client.phone_complete_form }}
                        </span>
                      </p>
                      <p v-if="allData.review">
                        <span>{{ $t("titles.review") + ": " }}</span>
                        <span>
                          {{ allData.review.comment }}
                          <i
                            v-if="allData.review.status == 'like'"
                            class="fas fa-thumbs-up"
                          ></i>
                          <i class="fas fa-thumbs-down" v-else></i>
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-6 col-xxl-4" v-if="allData.review">
                <div class="customer">
                  <div class="item">
                    <div class="icon">
                      <v-icon>fa-regular fa-user</v-icon>
                    </div>
                    <div class="info">
                      <h5 class="title">{{ $t("titles.review") }}</h5>
                      <span>
                        {{ allData.review.comment }}
                        <i
                          v-if="allData.review.status == 'like'"
                          class="fas fa-thumbs-up"
                        ></i>
                        <i class="fas fa-thumbs-down" v-else></i>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="col-md-6 col-xxl-4"
                v-if="!allData.is_pickup_from_branch"
              >
                <div class="customer">
                  <div class="item">
                    <div class="icon">
                      <v-icon>fa-regular fa-user</v-icon>
                    </div>

                    <!-- ||  (allData.is_assign_driver &&  allData.status == 'driver_cancel') -->
                    <div class="info">
                      <h5 class="title">{{ $t("titles.driver") }}</h5>
                      <template v-if="!allData.driver">
                        <!-- !allData.driver || allData.status == 'driver_cancel' -->
                        <v-btn
                          v-if="
                            (!allData.is_assign_driver &&
                              !allData.have_item_out_of_stock &&
                              allData.status == 'progress') ||
                            (!allData.is_assign_driver &&
                              allData.have_item_out_of_stock &&
                              allData.confirm_out_of_stock_status ==
                                'finished' &&
                              allData.status == 'progress')
                          "
                          class="px-10"
                          color="#1B5E20"
                          style="color: white"
                          @click="
                            selectDriveModal = true;
                            getDrivers();
                          "
                        >
                          {{ $t("assignDriver") }}
                        </v-btn>
                      </template>
                      <template v-else>
                        <p>
                          {{ $t("titles.name") }}:
                          {{ allData.driver.full_name }}
                        </p>

                        <p>
                          <span>{{ $t("titles.phone") }}</span>
                          <span>
                            {{ allData.driver.phone_complete_form }}
                          </span>
                        </p>
                      </template>
                      <v-btn
                        v-if="
                          allData.is_assign_driver &&
                          allData.status == 'progress'
                        "
                        class="px-10"
                        color="#1B5E20"
                        style="color: white"
                        @click="
                          selectDriveModal = true;
                          getDrivers();
                        "
                      >
                        {{ $t("changeDriver") }}
                      </v-btn>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-md-6 col-xxl-4" v-if="allData.address">
                <div class="customer">
                  <div class="item">
                    <div class="icon">
                      <v-icon>fa-solid fa-truck-moving</v-icon>
                    </div>
                    <div class="info">
                      <h5 class="title">
                        {{ $t("titles.shippingAddress") }}
                      </h5>
                      <p v-if="allData.address.country">
                        <span>{{ $t("titles.country") }}:</span>
                        <span>{{ allData.address.country.name }}</span>
                      </p>
                      <p v-if="allData.address.type">
                        <span>{{ $t("titles.name") }}:</span>
                        <span>{{ allData.address.type }}</span>
                      </p>

                      <p v-if="allData.address.building">
                        <span>{{ $t("titles.building") }}:</span>
                        <span>{{ allData.address.building }}</span>
                      </p>
                      <p v-if="allData.address.building">
                        <span> {{ $t("titles.floor") }}:</span>
                        <span>{{ allData.address.floor }}</span>
                      </p>
                      <p v-if="allData.address.apartment">
                        <span> {{ $t("titles.flatNum") }}:</span>
                        <span>{{ allData.address.apartment }}</span>
                      </p>

                      <p>
                        <span>{{ $t("titles.locationDesc") }}</span>
                        <span>
                          {{ allData.address.location_description }}
                        </span>
                      </p>
                      <p v-if="allData.address.place_description">
                        <span>{{ $t("titles.place_description") }}:</span>
                        <span>{{ allData.address.place_description }}</span>
                      </p>
                      <!-- <p>
                        <span>رقم الموبايل:</span>
                        <span>{{ allData.address.phone }}</span>
                      </p>
                    </div> -->
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12">
                <div class="order-info">
                  <div class="item">
                    <div class="icon">
                      <v-icon>fa-solid fa-cart-shopping</v-icon>
                    </div>
                    <div class="info">
                      <h5 class="title">{{ $t("titles.orderDetails") }}</h5>
                      <div class="d-flex gap-5 flex-wrap">
                        <div>
                          <p>
                            <span> {{ $t("titles.orderType") }}: </span>
                            <span>
                              {{
                                allData.order_type == "normal"
                                  ? $t("ordinary")
                                  : $t("sechudle")
                              }}
                            </span>
                          </p>
                          <p v-if="allData.distance">
                            <span> {{ $t("titles.distance") }}: </span>
                            <span>
                              {{ allData.distance }}
                            </span>
                          </p>
                          <p>
                            {{ $t("titles.payType") }}:
                            {{ allData.is_payment_trans }}
                          </p>
                        </div>
                        <div>
                          <p>
                            <span> {{ $t("titles.payWay") }}: </span>
                            <span>
                              {{ allData.pay_type }}
                            </span>
                          </p>
                          <p>
                            <span> {{ $t("titles.date") }}: </span>
                            <span>
                              {{ allData.scheduled_at }}
                            </span>
                          </p>
                          <p>
                            <span> {{ $t("delviery_type") }}: </span>
                            <span>
                              {{
                                allData.is_pickup_from_branch
                                  ? $t("pickup_from_branch")
                                  : $t("delivery_to_home")
                              }}
                            </span>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div
                class="col-12"
                v-if="allData.files.length || allData.images.length"
              >
                <div class="customer">
                  <div class="item">
                    <div class="icon">
                      <v-icon>fa-solid fa-file</v-icon>
                    </div>
                    <div class="info">
                      <h5 class="title">{{ $t("titles.prescription") }}</h5>
                      <div class="showFiles">
                        <div class="files">
                          <div
                            class="file"
                            v-for="(file, index) in allData.images"
                            :key="index"
                          >
                            <img
                              :src="file.image"
                              width="300"
                              height="150"
                              @click="show_model_1(file.image)"
                            />
                          </div>
                          <div
                            class="file"
                            v-for="(file, index) in allData.files"
                            :key="index"
                          >
                            <iframe
                              :src="file.image"
                              width="300"
                              height="150"
                            ></iframe>
                            <button class="btn" @click="show_model(file.image)">
                              {{ $t("labels.ope") }}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <transition name="fadeInUp">
                <div
                  v-if="model_1.show_model"
                  class="baseModel"
                  @click.stop="model_1.show_model = false"
                >
                  <div class="main" @click.stop>
                    <span
                      @click.stop="model_1.show_model = false"
                      class="close"
                    >
                      <v-icon>fas fa-times</v-icon>
                    </span>

                    <div class="notificationPopup">
                      <img :src="model_1.model_img_src" alt="img" />
                    </div>
                  </div>
                </div>

                <div
                  v-if="model.show_model"
                  class="baseModel"
                  @click.stop="model.show_model = false"
                >
                  <div class="main" @click.stop>
                    <span @click.stop="model.show_model = false" class="close">
                      <v-icon>fas fa-times</v-icon>
                    </span>

                    <div class="notificationPopup">
                      <iframe :src="model.model_file_src"></iframe>
                    </div>
                  </div>
                </div>
              </transition>
            </div>
            <!-- <div class="row w-100">
              <div class="col-lg-9">
              </div>
              <template v-if="allData.address_data">
                <div
                  class="col-lg-3"
                  style="height: 330px;"
                  v-if="allData.address_data.lat && allData.address_data.lat"
                >
                  <GmapMap
                    style="width: 100%; height: 280px;"
                    :zoom="10"
                    :center="{
                      lat: +allData.address_data.lat,
                      lng: +allData.address_data.lng,
                    }"
                    ref="map"
                  >
                    <GmapMarker
                      :position="{
                        lat: +allData.address_data.lat,
                        lng: +allData.address_data.lng,
                      }"
                      :draggable="false"
                    />
                  </GmapMap>
                  <div class="link mt-1 text-center">
                    <a
                      style="color: var(--mainColor-2);"
                      target="_blank"
                      :href="`https://maps.google.com/?q=${allData.address_data.lat},${allData.address_data.lng}`"
                      ><i class="fas fa-map"></i> {{ $t("location_link") }}</a
                    >
                  </div>
                </div>
              </template>
            </div> -->
          </div>

          <hr class="line" />
          <div class="product-details">
            <div class="row">
              <div class="col-lg-8">
                <div class="d-flex justify-content-between">
                  <h2 class="title">{{ $t("titles.products") }}</h2>
                  <div class="px-3 pb-1 frm-action d-flex flex-row gap-2">
                    <button
                      class="export btn"
                      :disabled="outLoading"
                      v-if="selected.length"
                      @click="makeOutOfStock"
                    >
                      {{ $t("makeOutOfStock") }}
                    </button>
                  </div>
                </div>
                <v-data-table
                  class="elevation-1 thumb diff_table"
                  :headers="headers"
                  :items="rows"
                  :search="search"
                  :loading="loading"
                  :show-select="
                    allData.status == 'order_placed' && !allData.driver
                  "
                  v-model="selected"
                  :loading-text="$t('table.loadingData')"
                  item-key="id"
                  :items-per-page="rows.length"
                  hide-default-footer
                >
                  <template v-slot:[`item.product_detail`]="{ item }">
                    <div class="d-flex align-items-center">
                      <img
                        :src="item.product_detail.image"
                        :alt="item.product_detail.name"
                        width="50"
                        height="50"
                        class="rounded-circle"
                      />
                      <span class="mx-2">{{ item.product_detail.name }}</span>
                    </div>
                  </template>
                  <template v-slot:[`item.out_of_stock`]="{ item }">
                    <span class="mx-2 status">{{
                      item.out_of_stock ? $t("out_of_stock") : $t("in_stock")
                    }}</span>
                  </template>
                  <template v-slot:no-data>
                    {{ $t("table.noData") }}
                  </template>
                </v-data-table>
                <div v-if="allData.note">
                  <h2 class="title mt-2">{{ $t("note") }}</h2>
                  <p class="fs-5 ps-5">"{{ allData.note }}"</p>
                </div>
              </div>
              <div class="col-lg-4">
                <div class="mb-4">
                  <h2 class="title">{{ $t("order_history") }}</h2>
                  <!-- <hr class="line" /> -->
                  <ul>
                    <li
                      style="
                        margin: 0px 20px;
                        font-size: 15px;
                        list-style-type: disc;
                      "
                      v-for="item in allData.order_status_times"
                      :key="item.id"
                    >
                      <div
                        v-for="(value, key) in item"
                        :key="key"
                        class="d-flex justify-content-between mb-2"
                      >
                        <div>{{ $t(`ordersStatus.${key}`) }}</div>
                        <div style="color: gray">{{ value }}</div>
                      </div>
                    </li>
                  </ul>
                  <hr class="line" />
                </div>
                <!-- <v-divider></v-divider> -->
                <div class="order-summary">
                  <h2 class="title">{{ $t("titles.orderSummary") }}</h2>
                  <hr class="line" />
                  <table>
                    <tbody>
                      <tr>
                        <td class="title">{{ $t("titles.cart") }}</td>
                        <td class="value">{{ rows.length }}</td>
                      </tr>
                      <tr
                        v-if="
                          allData.price_details
                            .total_item_price_before_discount !=
                          allData.price_details.total_item_price_after_discount
                        "
                      >
                        <td class="title">{{ $t("titles.cartBefore") }}</td>
                        <td class="value">
                          {{
                            allData.price_details
                              .total_item_price_before_discount
                          }}
                          {{ allData.price_details.currency }}
                        </td>
                      </tr>
                      <tr>
                        <td class="title">
                          {{ $t("titles.cartTotal") }}
                          {{
                            allData.price_details
                              .total_item_price_before_discount !=
                            allData.price_details
                              .total_item_price_after_discount
                              ? $t("titles.afterDis")
                              : " "
                          }}
                        </td>
                        <td class="value">
                          {{
                            allData.price_details
                              .total_item_price_after_discount
                          }}
                          {{ allData.price_details.currency }}
                        </td>
                      </tr>
                      <tr v-if="allData.price_details.coupon_price">
                        <td class="title">{{ $t("titles.copounDis") }}</td>
                        <td class="value">
                          {{ allData.price_details.coupon_price }}
                          {{ allData.price_details.currency }}
                        </td>
                      </tr>
                      <tr>
                        <td class="title">{{ $t("titles.deliveryPrice") }}</td>
                        <td class="value">
                          {{ allData.price_details.delivery_price }}
                          {{ allData.price_details.currency }}
                        </td>
                      </tr>
                      <tr>
                        <td class="title">{{ $t("titles.vat") }}</td>
                        <td class="value">
                          {{ allData.price_details.vat_price }}
                          {{ allData.price_details.currency }}
                        </td>
                      </tr>
                      <tr v-if="allData.price_details.discount">
                        <td class="title">{{ $t("titles.discount") }}</td>
                        <td class="value">
                          {{ allData.price_details.discount }}
                          {{ allData.price_details.currency }}
                        </td>
                      </tr>
                    </tbody>
                    <div class="line"></div>
                    <tfoot>
                      <tr>
                        <td class="title">{{ $t("titles.total") }}</td>
                        <td class="value">
                          {{ allData.price_details.total_price }}
                          {{ allData.price_details.currency }}
                        </td>
                      </tr>
                      <!-- <tr>
                        <td class="title status">الحالة</td>
                        <td class="value">
                          <span
                            v-if="allData.status == 'finished'"
                            class="status success bg-transparent"
                          >
                            {{ allData.status_trans }}
                          </span>
                          <span
                            v-else-if="allData.status == 'store_reject'"
                            class="status canceled bg-transparent"
                          >
                            {{ allData.status_trans }}
                          </span>
                          <span v-else class="status pending bg-transparent">
                            {{ allData.status_trans }}
                          </span>
                        </td>
                      </tr> -->
                    </tfoot>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- End Statistics Card-->
        <!-- Start:: Text Content Modal -->
        <DeleteModel
          :id="allData.id"
          @toggleModal="toggleDeleteModal"
          :modalIsActive="deleteModalIsOpen"
          :elementIdToGet="deleteItemToShow"
        />
        <!-- End:: Text Content Modal -->
      </div>
    </transition-group>
    <v-dialog class="" v-model="reject_reason_model" width="500" persistent>
      <v-card>
        <v-card-title> {{ $t("messages.orderCancel") }}</v-card-title>
        <v-card-text>
          <div class="input_wrapper">
            <!-- :label="allData.status_trans" -->

            <v-select
              solo
              v-model="reject_reason"
              :items="reject_reasons"
              style="
                border: 1px solid var(--mainColor-2);
                border-radius: 10px;
                padding: 10px !important;
              "
            ></v-select>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="#F44336"
            :disabled="!reject_reason"
            @click="changeStatus"
          >
            تأكيد
          </v-btn>
          <v-btn color="#1B5E20" @click="reject_reason_model = false">
            رجوع
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog class="" v-model="selectDriveModal" width="500">
      <v-card>
        <v-card-title> {{ $t("selectDriver") }}</v-card-title>
        <v-card-text>
          <div class="input_wrapper top_label">
            <v-select
              :label="$t('selectDriver')"
              solo
              :items="drivers"
              v-model="selectedDriver"
            ></v-select>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="#F44336"
            :disabled="!selectedDriver"
            @click="assignDrive"
          >
            تأكيد
          </v-btn>
          <v-btn color="#1B5E20" @click="selectDriveModal = false">
            رجوع
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

// Start:: Importing Modals
import DeleteModel from "@/components/Modals/DeleteModel.vue";
// End:: Importing Modals

export default {
  props: ["id"],
  name: "Withdraw",

  components: {
    DeleteModel,
  },

  data() {
    return {
      // ========== Breadcrumbs
      items: [
        {
          text: this.$t("breadcrumb.mainPage"),
          disabled: false,
          href: "/",
        },
        {
          text: this.$t("breadcrumb.orders.title"),
          disabled: true,
          href: "",
        },

        // {
        //   text: this.$t("breadcrumb.transaction.products"),
        //   disabled: true,
        //   href: "",
        // },
      ],

      // ========== Statistics
      statisticsItem: {},
      rows: null,
      reject_reasons: [],
      // ========== Top Section
      search: "",

      rejectedReason: "",

      deleteModalIsOpen: false,
      deleteItemToShow: null,
      selectedStatus: null,

      // ========== Your Data
      allData: null, // injected in created
      model: {
        show_model: false,
        model_file_src: "",
      },

      model_1: {
        show_model: false,
        model_img_src: "",
      },
      // ========== Loading
      loading: false,
      reportReasonToShow: null,
      btnIsLoading: false,
      outLoading: false,
      e1: 1,
      selected: [],
      drivers: [],

      // reject reason
      reject_reason_model: false,
      selectDriveModal: false,
      selectedDriver: null,
      reject_reason: null,
      json_meta: [
        [
          {
            key: "charset",
            value: "utf-8",
          },
        ],
      ],
    };
  },

  computed: {
    ...mapGetters({
      lang: "lang_module/lang",
    }),
    headers() {
      if (this.lang == "ar") {
        return [
          {
            text: "المنتج",
            align: "center",
            value: "product_detail",
            sortable: false,
          },
          {
            text: "كود المنتج",
            align: "center",
            value: "product_detail.code",
            sortable: false,
          },
          {
            text: "حالة المخزون",
            align: "center",
            value: "out_of_stock",
            sortable: false,
          },
          // {
          //   text: "الوصف",
          //   align: "center",
          //   value: "product.desc",
          //   sortable: false,
          // },
          {
            text: "سعر المنتج",
            align: "center",
            value: "price",
            sortable: false,
          },
          {
            text: "الكمية",
            align: "center",
            value: "quantity",
            sortable: false,
          },
          {
            text: "السعر الكلي",
            align: "center",
            value: "price",
            sortable: false,
          },
        ];
      } else {
        return [
          {
            text: "Product",
            align: "center",
            value: "product_detail",
            sortable: false,
          },
          {
            text: "Product Code",
            align: "center",
            value: "product_detail.code",
            sortable: false,
          },
          {
            text: "Stock status",
            align: "center",
            value: "out_of_stock",
            sortable: false,
          },
          // {
          //   text: "الوصف",
          //   align: "center",
          //   value: "product.desc",
          //   sortable: false,
          // },
          {
            text: "Price",
            align: "center",
            value: "price",
            sortable: false,
          },
          {
            text: "Quantity",
            align: "center",
            value: "quantity",
            sortable: false,
          },
          {
            text: "Total Price",
            align: "center",
            value: "total_price",
            sortable: false,
          },
        ];
      }
    },
  },
  watch: {
    reject_reason_model(newVal) {
      if (newVal == true) {
        this.getReasons();
      } else if (newVal == false) {
        this.selectedStatus = this.allData.status;
      }
    },
  },
  methods: {
    show_model_1(src) {
      this.model_1.model_img_src = src;
      this.model_1.show_model = true;
    },
    show_model(src) {
      this.model.model_file_src = src;
      this.model.show_model = true;
    },
    exportFields() {
      let newData = [];
      this.rows.forEach((el) => {
        let newObject = {};
        // newObject[`${this.$t("forms.labels.image")}`] = el?.media.length
        //   ? el.media[0].image
        //   : this.$t("notFound");
        newObject[`${this.$t("forms.labels.name")}`] = el.product_detail.name;
        newObject[`${this.$t("forms.labels.product_code")}`] =
          el.product_detail.code;
        newObject[`${this.$t("forms.labels.price")}`] = el.price;
        newObject[`${this.$t("forms.labels.quantity")}`] = el.quantity;
        newObject[`${this.$t("forms.labels.total_price")}`] = el.total_price;
        // newObject[`${this.$t("forms.labels.status")}`] = el.is_active
        //   ? this.$t("active")
        //   : this.$t("inactive");
        // newObject[`${this.$t("forms.labels.city")}`] = el.city;
        newData.push(newObject);
      });
      return newData;
    },
    // ===== Toggle Delete Modal =====
    toggleDeleteModal(item) {
      this.deleteItemToShow = item;
      this.deleteModalIsOpen = !this.deleteModalIsOpen;
    },

    // Set Rows
    setRows() {
      this.loading = true;
      this.$axios({
        method: "GET",
        url: "order/" + this.id,
      })
        .then((res) => {
          this.allData = res.data.data;

          this.rows = res.data.data.items;

          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    //Change Status
    changeStatus(event, e) {
      let currStatus;
      if (e) {
        currStatus = e;
      } else {
        currStatus = this.selectedStatus ?? "admin_reject";
      }

      this.btnIsLoading = true;
      let submit_data = new FormData();
      let keepGoing = true;
      if (currStatus == "admin_cancel") {
        if (!this.reject_reason) {
          if (!this.reject_reason_model) {
            this.reject_reason_model = true;

            return;
          }
          this.$iziToast.error({
            timeout: 2000,
            message: this.$t("forms.validation.reject_reason"),
            position: "bottomRight",
          });
          keepGoing = false;
        }

        submit_data.append("reject_reason_id", this.reject_reason);
      }
      if (currStatus == "admin_reject") {
        if (!this.reject_reason) {
          this.$iziToast.error({
            timeout: 2000,
            message: this.$t("forms.validation.reject_reason"),
            position: "bottomRight",
          });
          keepGoing = false;
        }

        submit_data.append("reject_reason_id", this.reject_reason);
      }
      if (currStatus == "on_the_way") {
        submit_data.append("driver_id", this.allData.driver?.id);
        // if (!this.selectedDriver) {
        //   this.getDrivers();
        //   keepGoing = false;
        // }
        // else {
        //   keepGoing = true;
        // }
      }
      if (!keepGoing) {
        return;
      }

      submit_data.append("status", currStatus);
      submit_data.append("_method", "PUT");
      this.$axios({
        method: "POST",
        url: `order/${this.id}/change_status`,
        data: submit_data,
      })
        .then((res) => {
          this.$iziToast.success({
            timeout: 2000,
            message: res.data.message,
            position: "bottomRight",
          });
          this.reject_reason_model = false;
          // this.$router.push({ path: "/transactions/all-transactions" });
          this.btnIsLoading = false;
          this.selectDriveModal = false;
          this.selectedDriver = null;
          this.setRows();
        })
        .catch((err) => {
          this.selectedDriver = null;

          this.$iziToast.error({
            timeout: 2000,
            message: err.response.data.message,
            position: "bottomRight",
          });

          this.setRows();
          this.btnIsLoading = false;
        });
    },

    assignDrive() {
      const submit_data = new FormData();
      submit_data.append("driver_id", this.selectedDriver);
      submit_data.append("_method", "PUT");
      this.$axios({
        method: "POST",
        url: `order/${this.id}/assign_driver_to_client`,
        data: submit_data,
      })
        .then((res) => {
          this.$iziToast.success({
            timeout: 2000,
            message: res.data.message,
            position: "bottomRight",
          });
          this.btnIsLoading = false;
          this.selectDriveModal = false;
          this.selectedDriver = null;
          this.setRows();
        })
        .catch((err) => {
          this.selectedDriver = null;
          if (err.response.data.status == "fail") {
            this.$iziToast.error({
              timeout: 2000,
              message: err.response.data.message,
              position: "bottomRight",
            });
          }

          this.setRows();
          this.btnIsLoading = false;
        });
    },

    orderStatusList(status) {
      if (this.allData.is_pickup_from_branch) {
        if (status == "pending") {
          return [
            {
              text: this.$t("accept"),
              value: "order_placed",
            },
            { text: this.$t("reject"), value: "admin_reject" },
          ];
        } else if (status == "order_placed") {
          return [
            {
              text: this.$t("progress"),
              value: "progress",
            },
            { text: this.$t("cancel"), value: "admin_cancel" },
          ];
        } else if (status == "progress") {
          return [
            {
              text: this.$t("deliverd"),
              value: "delivered",
            },
            { text: this.$t("cancel"), value: "admin_cancel" },
          ];
        }
      } else {
        if (status == "pending") {
          return [
            {
              text: this.$t("accept"),
              value: "order_placed",
            },
            { text: this.$t("reject"), value: "admin_reject" },
          ];
        } else if (status == "order_placed") {
          return [
            {
              text: this.$t("progress"),
              value: "progress",
            },
            { text: this.$t("cancel"), value: "admin_cancel" },
          ];
        } else if (status == "progress") {
          return [
            {
              text: this.$t("on_the_way"),
              value: "on_the_way",
            },
            { text: this.$t("cancel"), value: "admin_cancel" },
          ];
        } else if (status == "on_the_way") {
          return [
            {
              text: this.$t("arrived"),
              value: "arrived",
            },
            { text: this.$t("cancel"), value: "admin_cancel" },
          ];
        } else if (status == "arrived") {
          return [
            {
              text: this.$t("delivered"),
              value: "delivered",
            },
            { text: this.$t("cancel"), value: "admin_cancel" },
          ];
        }
      }
    },
    makeOutOfStock() {
      this.outLoading = true;
      let submit_data = new FormData();
      submit_data.append("status", "progress");
      this.selected.map((el, i) =>
        submit_data.append(`out_of_stock_ids[${i}]`, el.id)
      );

      submit_data.append("_method", "PUT");
      this.$axios({
        method: "POST",
        url: `order/${this.id}/change_status`,
        data: submit_data,
      })
        .then((res) => {
          this.$iziToast.success({
            timeout: 2000,
            message: res.data.message,
            position: "bottomRight",
          });
          this.selected = [];
          // this.$router.push({ path: "/transactions/all-transactions" });
          this.outLoading = false;
          this.setRows();
        })
        .catch((err) => {
          if (err.response.data.status == "fail") {
            this.$iziToast.error({
              timeout: 2000,
              message: err.response.data.message,
              position: "bottomRight",
            });
          }

          this.setRows();
          this.outLoading = false;
        });
    },
    orderStatus(status) {
      let message = "";
      switch (status) {
        case "pending":
          message = this.$t("orderStatus.new");
          break;
        case "store_accept ":
          message = this.$t("orderStatus.new");

          break;
        case "in_progress":
          message = this.$t("orderStatus.progress");

          break;
        case "store_reject":
          message = this.$t("orderStatus.reject");

          break;
        case "admin_cancel":
          message = this.$t("orderStatus.cancel");

          break;
        default:
      }
      return message;
    },

    getDrivers() {
      this.$axios
        .get(`drivers?order_id=${this.id}`)
        .then((res) => {
          this.drivers = res.data.data.map((el) => ({
            value: el.id,
            text: el.full_name,
          }));
        })
        .catch(() => (this.selectDriveModal = true));
    },
    getReasons() {
      this.$axios
        .get(`reject_reasons`)
        .then((res) => {
          this.reject_reasons = res.data.data.map((el) => ({
            value: el.id,
            text: el.title,
          }));
        })
        .catch(() => (this.selectDriveModal = true));
    },
  },
  created() {
    this.setRows();
    // this.getDrivers();
  },

  // ======= hooks
};
</script>

<style lang="scss">
.order-details-page {
  background: #fff;
  border-radius: 20px;
  box-shadow: 0 2px 8px rgb(0 0 0 / 11%);
  padding: 20px 0;

  .baseModel {
    width: 100vw;
    height: 100vh;
    position: fixed;
    inset: 0;
    z-index: 999;
    background: #333333d4;
    display: flex;
    justify-content: center;
    align-items: center;
    .notificationPopup {
      text-align: center;
      img {
        width: 100%;
        max-height: 600px;
        border-radius: 10px;
      }
      iframe {
        width: 100%;
        height: 600px;
        border-radius: 10px;
      }
    }
    .main {
      // width: 800px;

      height: auto;
      background: #fff;
      position: relative;
      border-radius: 10px;
      max-height: 70vh;
      // overflow-y: scroll;
      padding: 22px 30px;
      font-size: 16px;
      min-height: 200px;
      width: 80vw;

      .close {
        position: absolute;
        top: -15px;
        right: -15px; // RTL
        cursor: pointer;
        width: 30px;
        height: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: var(--mainColor-2);
        border-radius: 50%;
        z-index: 99;

        i {
          color: #fff;
          font-size: 20px;
        }
      }
    }
  }
  .showFiles {
    .files {
      display: flex;
      gap: 10px;
      flex-wrap: wrap;
      margin-top: 5px;
      // max-width: inherit;
      // overflow-x: scroll;

      .file {
        position: relative;
        display: flex;
        flex-direction: column;
        row-gap: 10px;

        button.btn {
          background: var(--mainColor-2) !important;
          color: #fff !important;
          height: 40px;
          font-size: 16px;
          width: fit-content;
          align-self: center;
          line-height: 30px;
          display: flex;
          align-items: center;
          justify-content: space-evenly;
        }

        iframe {
          width: 150px;
          height: 150px;
          border-radius: 10px;
        }

        img {
          width: 150px;
          height: 150px;
          border-radius: 10px;
        }
      }
    }
  }
  h2.title {
    font-size: 14px !important;
    padding: 0 10px;
    font-weight: bold !important;
    text-align: start !important;
  }
  span.status {
    font-size: 14px;
    &.finished {
      color: #02bc77;
    }
    &.pendding {
      color: #ffc800;
    }
    &.shipping {
      color: #a900ff;
    }
  }
  .status {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    padding: 0 10px;
  }
  .action {
    .v-input {
      padding: 0;
      margin: 0;
    }
    .v-input__control {
      background: #e7e7e7;
      padding: 8px;
      max-width: 200px;
      border-radius: 5px;
    }
    .v-text-field__details {
      display: none !important;
    }
    .v-input__slot {
      margin: 0;
      &::before {
        content: unset !important;
      }
    }
    .v-select__slot {
      &::after {
        content: unset !important;
      }
    }
    input[role="readonly"] {
      display: none !important;
    }
  }
  .details {
    padding: 0 10px;
    h5.title {
      color: #000;
      font-size: 14px !important;
      font-weight: bold;
      margin-bottom: 0%;
    }
    .item {
      display: flex;
      align-items: flex-start;
      .icon {
        background: #f5f5fa;
        border-radius: 50%;
        width: 50px !important;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 50px !important;
        * {
          color: #000;
        }
      }
      .info {
        flex: 1;
        margin-inline-start: 8px;
        background: none !important;
        p {
          font-size: 14px;
          color: #8d8d8d;
          margin-bottom: 0;
          &:not(:last-child) {
            margin-bottom: 4px;
          }
        }
      }
    }
  }
  .product-details {
    padding: 0 10px;
    h2.title {
      font-size: 18px !important;
    }
  }
  table {
    width: 100%;
    td.title {
      font-size: 16px !important;
      line-height: 1.7;
      &.status {
        font-size: 14px !important;
        padding: 0;
      }
    }
    td.value {
      font-size: 14px !important;
      font-weight: bold;
      text-align: end;

      span.status {
        justify-content: flex-end;
        padding: 0;
      }
    }
  }

  div.line {
    padding: 10px 0;
  }
  tfoot {
    border-top-width: 1px;
    margin-top: 10px;
  }
}

.btns_actions {
  display: flex;
  justify-content: center;
  align-items: center;
  button {
    margin: 5px;
  }
}
</style>
