var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"homePage"},[_c('Breadcrumb',{attrs:{"items":_vm.items}}),(true)?_c('CurtainLoader'):_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-12"},[_c('main',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"row"},_vm._l((12),function(n){return _c('div',{key:n,staticClass:"col-sm-6 col-md-4 col-lg-3"},[_c('SparksAreaCharts',{attrs:{"dir":"ltr","SectionRoute":'/users/all',"ChartColor":'#CD201F',"ChartTitle":"","ChartName":"الزائرين اليوم","ChartLabels":[
                    'User 1',
                    'User 2',
                    'User 3',
                    'User 4',
                    'User 5',
                    'User 6',
                  ],"ChartData":[57, 35, 40, 48, 56, 38]},scopedSlots:_vm._u([{key:"chart_title",fn:function(){return [_c('span',{staticClass:"title_text"},[_vm._v("احصائيات")]),_c('p',{staticClass:"chart_val",style:({ color: '#CD201F' })},[_vm._v("20")])]},proxy:true}],null,true)})],1)}),0),_c('div',{staticClass:"row"},[(_vm.news)?_c('div',{staticClass:"col-sm-6 col-md-4"},[_c('NewsCard',{attrs:{"items":_vm.news,"height":"400px ","title":"اخر الاخبار"}})],1):_vm._e(),_c('div',{staticClass:"col-sm-6 col-md-8"},[(_vm.champions_expectations_order)?_c('UsersStatisticsTable',{attrs:{"tableRows":_vm.champions_expectations_order}}):_vm._e()],1)])]),(_vm.leagues_chart)?_c('div',{staticClass:"col-md-6"},[_c('ColumnChart',{attrs:{"dir":"ltr","ChartLabels":_vm.leagues_chart.labels,"ChartData":_vm.leagues_chart.series},scopedSlots:_vm._u([{key:"chart_title",fn:function(){return [_c('span',{staticClass:"title_text"},[_vm._v(" "+_vm._s(_vm.$t("leagues"))+" ")])]},proxy:true}],null,false,394392289)})],1):_vm._e(),(_vm.teams_chart)?_c('div',{staticClass:"col-md-6"},[_c('ColumnChart',{attrs:{"dir":"ltr","ChartLabels":_vm.teams_chart.labels,"ChartData":_vm.teams_chart.series},scopedSlots:_vm._u([{key:"chart_title",fn:function(){return [_c('span',{staticClass:"title_text"},[_vm._v(" "+_vm._s(_vm.$t("teams"))+" ")])]},proxy:true}],null,false,1918392707)})],1):_vm._e(),(_vm.users_chart)?_c('div',{staticClass:"col-md-6"},[_c('ColumnChart',{attrs:{"dir":"ltr","ChartLabels":_vm.users_chart.labels,"ChartData":_vm.users_chart.series},scopedSlots:_vm._u([{key:"chart_title",fn:function(){return [_c('span',{staticClass:"title_text"},[_vm._v(" "+_vm._s(_vm.$t("theUsers"))+" ")])]},proxy:true}],null,false,2049493206)})],1):_vm._e()])])])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }