<template>
  <div :class="col ? `col-md-${col}` : ''">
    <div class="flagged_phone_content_wrapper w-100">
      <!-- Start:: Flag & Country Key -->
      <div class="position-relative">
        <!-- ********** Start:: Selected Flag & Key ********** -->
        <button
          type="button"
          class="selected_flag_wrapper"
          @click.stop="toggleCountriesKeysMenu"
          v-if="selectedCountry"
        >
          <span class="selected_country_key">
            {{ selectedCountry.key }}
          </span>
          <img
            class="selected_country_flg"
            :src="selectedCountry.flag"
            alt=""
            width="30"
            height="22"
          />
        </button>
        <!-- ********** End:: Selected Flag & Key ********** -->

        <!-- ********** Start:: Flags & Keys List ********** -->
        <transition name="fadeInUp">
          <ul class="keys_and_flags_list" v-if="countriesKeysMenuIsOpen">
            <li
              v-for="item in countriesKeys"
              :key="item.id"
              @click.stop="selectKey(item)"
              class="list_item"
            >
              <span class="country_key">
                {{ item.key }}
              </span>
              <img
                class="country_flg"
                :src="item.flag"
                alt=""
                width="30"
                height="22"
              />
            </li>
          </ul>
        </transition>
        <!-- ********** End:: Flags & Keys List ********** -->
      </div>
      <!-- End:: Flag & Country Key -->
      <!-- Start:: Phone Input -->
      <div class="input_wrapper">
        <v-text-field
          type="number"
          @keypress="preventMinus"
          :label="placeholder"
          :value="value"
          @blur="validateInput(value)"
          @input="updateValue($event)"
          :required="required"
        ></v-text-field>
      </div>
      <!-- End:: Phone Input -->
    </div>
  </div>
</template>

<script>
export default {
  name: "BaseCountryFlagPhoneInput",

  props: {
    // ====== General Inputs Props
    value: {
      required: true,
    },
    placeholder: {
      required: true,
      type: String,
    },
    col: {
      required: false,
      type: String,
    },
    preSelectedPhoneCode: {
      required: false,
      type: String,
    },
    required: {
      required: false,
      type: Boolean,
      default: false,
    },
    only: {
      required: false,
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      // Validation Alert
      showAlert: false,
      validationMessage: "errer",

      countriesKeys: [],

      // Start:: control Countries Keys Menu Data
      countriesKeysMenuIsOpen: false,
      // End:: control Countries Keys Menu Data

      // Start:: Selected Country Data
      selectedCountry: null,
      // End:: Selected Country Data

      // Start:: Front End Input Validation
      frontEndInputValidation: {
        inputValue: [(val) => (val || "").length > 0 || this.validationHint],
      },
      // End:: Front End Input Validation
    };
  },

  methods: {
    // Start:: Update Input Value
    updateValue(e) {
      this.$emit("input", e);
    },
    // End:: Update Input Value

    // Start:: Toggle Countries Keys Menu
    toggleCountriesKeysMenu() {
      this.countriesKeysMenuIsOpen = !this.countriesKeysMenuIsOpen;
    },
    // End:: Toggle Countries Keys Menu

    // Start:: Update Selected Country Key Value
    updateSelectedCountryKeyValue() {
      this.$emit("changeKey", {
        key: this.selectedCountry.key,
        limit: this.selectedCountry.limit,
        id: this.selectedCountry.id,
      });
    },
    // End:: Update Selected Country Key Value

    // Start:: Select Country Key
    selectKey(country) {
      this.selectedCountry = country;
      this.updateSelectedCountryKeyValue();
      this.toggleCountriesKeysMenu();
    },
    // End:: Select Country Key

    validateInput(value) {
      let valid = true;

      if (!value) {
        this.validationMessage = this.$t("validation.empty");
        valid = false;
      }

      this.showAlert = !valid;
    },

    getCountryeKeys() {
      this.$axios({
        method: "GET",
        url: `countries`,
      })
        .then((res) => {
          this.countriesKeys = res.data.data.map((item) => {
            return {
              id: item.id,
              flag: item.image,
              key: item.phone_code,
              limit: item.phone_limit,
            };
          });

          // ********** Start:: Set Initial Selected Key **********

          if (this.preSelectedPhoneCode) {
            this.selectedCountry = this.countriesKeys.find(
              (country) => country.key == this.preSelectedPhoneCode
            );
          } else {
            this.selectedCountry = this.countriesKeys[0];
          }
          this.updateSelectedCountryKeyValue();
          // ********** End:: Set Initial Selected Key **********
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },

  created() {
    // Start:: Fire Methods
    this.getCountryeKeys();
    window.addEventListener("click", () => {
      this.countriesKeysMenuIsOpen = false;
    });
    // End:: Fire Methods
  },
};
</script>
<style lang="scss">
.flagged_phone_content_wrapper {
  @include flexStartAlignment;
  align-items: flex-start;
  column-gap: 8px;

  .selected_flag_wrapper {
    @include flexEndAlignment;
    flex-direction: row-reverse;
    column-gap: 5px;
    width: 90px;
    height: 100%;
    padding: 11px 5px;
    background-color: var(--inputBg);
    border-inline-end: 1px solid var(--main);

    .selected_country_key {
      direction: ltr;
      color: var(--lightGray);
      font-size: 15px;
      font-family: mediumArFont;
    }

    .selected_country_flg {
      border-radius: 4px;
      object-fit: cover;
    }
  }

  .keys_and_flags_list {
    position: absolute;
    top: 110%;
    left: 0;
    direction: ltr;
    width: calc(100px - 10px);
    background-color: #fff;
    border: 1px solid var(--main);
    border-radius: 10px;
    @include midShadow;
    overflow: hidden;
    z-index: 10;
    max-height: 300px;
    overflow-y: scroll;

    .list_item {
      @include flexEndAlignment;
      column-gap: 5px;
      padding: 10px 6px;
      transition: all 0.4s linear;

      &:hover {
        cursor: pointer;
        background-color: var(--softMain);
      }

      .country_key {
        direction: ltr;
        color: var(--lightGray);
        font-size: 12px;
        font-family: mediumArFont;
      }

      .country_flg {
        border-radius: 4px;
        object-fit: cover;
      }
    }
  }

  .input_wrapper {
    width: calc(100% - 90px);
  }
}
</style>
