<template>
  <div class="wrraper">
    <!-- Start Breadcrumb -->
    <Breadcrumb :items="items" />
    <!-- End Breadcrumb -->

    <transition-group name="fadeInUp" mode="in-out">
      <!-- Start Main Loader -->
      <!-- <MainLoader key="loader" v-if="loading" /> -->
      <!-- End Main Loader -->

      <div key="main">
        <main-filter
          :withSearch="false"
          :orderId="true"
          :customerNumber="true"
          :delievery_type="true"
          col="3"
          @getResult="setRows"
        />

        <main>
          <v-data-table
            class="elevation-1 thumb diff_table"
            :headers="headers"
            :items="rows"
            :search="search"
            :custom-filter="helper_filterSearch"
            :loading="loading"
            :loading-text="$t('table.loadingData')"
            item-key="id"
            :items-per-page="paginations.items_per_page"
            hide-default-footer
            :item-class="itemRowBackground"
          >
            <!-- ================== You Can use any slots you want ================== -->
            <!-- Select no data State -->
            <template v-slot:no-data>
              {{ $t("table.noData") }}
            </template>

            <template v-slot:[`item.index`]="{ index }">
              {{ index + 1 }}
            </template>
            <template v-slot:[`item.client`]="{ item }">
              <div class="d-flex flex-column align-items-center">
                <span v-if="item.client">{{
                  item.client.phone_complete_form
                }}</span>
                <span v-else>{{ $t("notFound") }}</span>
              </div>
            </template>

            <template v-slot:[`item.is_payment`]="{ item }">
              <span class="status" :class="item.is_payment ? 'paid' : 'unpaid'">
                {{ item.is_payment ? $t("paid") : $t("unpaid") }}
              </span>
            </template>
            <template v-slot:[`item.order_type`]="{ item }">
              <span class="status">
                {{
                  item.order_type == "normal" ? $t("ordinary") : $t("sechudle")
                }}
              </span>
            </template>
            <template v-slot:[`item.scheduled_at`]="{ item }">
              <span v-if="item.scheduled_at">
                {{ item.scheduled_at }}
              </span>
              <p v-if="item.times">
                {{
                  $i18n.locale == "ar" ? item.times.day_trans : item.times.day
                }}
              </p>
              <p dir="ltr" v-if="item.times">
                {{ item.times.from + " to " + item.times.to }}
              </p>
            </template>
            <template v-slot:[`item.address`]="{ item }">
              <div v-if="item.address">
                <div
                  @click="showAddress(item.address)"
                  class="text-truncate"
                  style="width: 200px"
                >
                  {{ item.address.location_description }}
                </div>
              </div>
              <div v-else>{{ $t("notFound") }}</div>
            </template>
            <template v-slot:[`item.status`]="{ item }">
              <div class="action">
                <div
                  v-if="
                    item.status == 'delivered' || item.have_item_out_of_stock
                  "
                >
                  <span
                    class="status canceled"
                    v-if="item.have_item_out_of_stock"
                  >
                    {{ $t("have_item_out_of_stock") }}
                  </span>
                  <span class="status success" v-else>
                    {{ item.status_trans }}
                  </span>
                </div>
                <div v-else-if="item.status == 'order_placed'">
                  <!-- <span class="status success">
                    {{ item.status_trans }}
                  </span> -->
                  <div class="input_wrapper">
                    <v-select
                      :label="item.status_trans"
                      solo
                      :items="orderStatusList(item.status, item)"
                      @input="changeStatus($event, item.id)"
                    ></v-select>
                  </div>
                </div>
                <div v-else-if="item.status == 'progress'">
                  <!-- <span class="status success">
                    {{ item.status_trans }}
                  </span> -->
                  <div class="input_wrapper">
                    <v-select
                      :label="item.status_trans"
                      solo
                      :items="orderStatusList(item.status, item)"
                      @input="changeStatus($event, item.id)"
                    ></v-select>
                  </div>
                </div>

                <div
                  v-else-if="
                    item.status == 'admin_reject' ||
                    item.status == 'client_cancel' ||
                    item.status == 'admin_cancel' ||
                    item.status == 'admin_cancel_after_delivered'
                  "
                >
                  <span class="status canceled">
                    {{ item.status_trans }}
                  </span>
                </div>
                <div class="select-wrapper" v-else>
                  <!-- <div class="mb-2">
                    {{ item.status_trans }}
                  </div> -->
                  <div class="d-flex gap-3 justify-content-center">
                    <button
                      class="status_btn accept"
                      @click="changeStatus('order_placed', item.id)"
                    >
                      <i class="fa fa-check"></i>
                      <div>{{ $t("accept") }}</div>
                    </button>
                    <button
                      class="status_btn reject"
                      @click="openRejectModel(item.id)"
                    >
                      <i class="fa fa-xmark"></i>
                      <div>{{ $t("reject") }}</div>
                    </button>
                  </div>
                  <!-- <div class="input_wrapper">
                    <v-select
                      :label="item.status_trans"
                      solo
                      :items="orderStatusList(item.status)"
                      @input="changeStatus($event, item.id)"
                    ></v-select>
                  </div> -->
                </div>
              </div>
            </template>
            <template v-slot:[`item.order_details.delivery_date`]="{ item }">
              <span>
                {{
                  item.order_details.delivery_date
                    ? item.order_details.delivery_date
                    : $t("notFound")
                }}
              </span>
            </template>
            <template v-slot:[`item.order_details.delivery_timing`]="{ item }">
              <span>
                {{
                  item.order_details.delivery_date
                    ? item.order_details.delivery_date
                    : $t("notFound")
                }}
              </span>
            </template>
            <template v-slot:[`item.to`]="{ item }">
              <router-link :to="`/users/show/${item.to.id}`">
                {{ item.to.fullname }}
              </router-link>
            </template>
            <template v-slot:[`item.is_pickup_from_branch`]="{ item }">
              <div>
                {{
                  item.is_pickup_from_branch
                    ? $t("is_pick_up_from_store")
                    : $t("delievery")
                }}
              </div>
            </template>
            <!-- End:: Users Routes -->

            <!-- Start:: Report Reason Modal Button -->
            <template v-slot:[`item.report_reason`]="{ item }">
              <v-icon
                class="show"
                small
                @click="controlReportReasonModalModal(item.report_reason)"
                v-if="item.report_reason"
              >
                fal fa-eye
              </v-icon>

              <span v-else>_</span>
            </template>
            <!-- End:: Report Reason Modal Button -->

            <!-- Select actions-->
            <template v-slot:[`item.actions`]="{ item }">
              <div class="_actions">
                <v-icon class="show" small @click="showItem(item)">
                  fal fa-eye
                </v-icon>
                <!-- <v-icon class="edit" small @click="editItem(item)">
                  fal fa-edit
                </v-icon> -->
                <!-- <v-icon class="delete" small @click="deleteItem(item)">
                  fal fa-trash
                </v-icon> -->
              </div>
            </template>

            <!-- ======================== Start Top Section ======================== -->
            <template v-slot:top>
              <h3 class="title table-title">
                {{ $t("titles.ordersNum") }} {{ statisticsItem.number }}
              </h3>
              <!-- Delete dialog -->
              <v-dialog v-model="dialogDelete" max-width="500px">
                <v-card>
                  <v-card-title class="text-h5 justify-center">
                    {{ $t("table.deletedialog.areYouSure") }}
                  </v-card-title>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="#1B5E20" @click="deleteItemConfirm">
                      {{ $t("table.deletedialog.ok") }}
                    </v-btn>
                    <v-btn color="#F44336" @click="dialogDelete = false">
                      {{ $t("table.deletedialog.cancel") }}
                    </v-btn>
                    <v-spacer></v-spacer>
                  </v-card-actions>
                </v-card>
              </v-dialog>

              <!-- Start:: Text Content Modal -->
              <TextContentModal
                @toggleModal="controlReportReasonModalModal"
                :modalIsActive="reportReasonModalIsOpen"
                :title="$t('reportReason')"
                :textContent="reportReasonToShow"
              />
              <!-- End:: Text Content Modal -->
            </template>
            <!-- ======================== End Top Section ======================== -->
          </v-data-table>
        </main>

        <template>
          <div
            class="pagination_container text-center mb-5 d-flex justify-content-end"
          >
            <!-- <div class="select-pagination d-flex">
              <span class="first">{{ $t('show') }}</span>
              <v-select
                :items="[5, 20, 50, 100]"
                v-model="paginations.items_per_page"
              ></v-select>
              <span>{{ $t('entries') }}</span>
            </div> -->

            <v-pagination
              v-model.number="paginations.current_page"
              :length="paginations.last_page"
              :total-visible="7"
              @input="fetchData($event)"
            ></v-pagination>
          </div>
        </template>
        <!-- End Pagination -->
      </div>
    </transition-group>
    <base-model
      @closeModel="model_1.show_model = false"
      :show="model_1.show_model"
    >
      <div class="image">
        <img
          v-if="model_1.model_img_src"
          :src="model_1.model_img_src"
          alt="..."
        />
      </div>
    </base-model>
    <v-dialog v-model="model_2.show_model" width="500">
      <v-card>
        <v-card-text class="text-center">
          {{ model_2.text }}
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog class="" v-model="reject_reason_model" width="500">
      <v-card>
        <v-card-title> {{ $t("messages.orderCancel") }} </v-card-title>
        <v-card-text>
          <v-textarea
            v-model="reject_reason"
            class="w-100 p-5"
            :placeholder="$t('forms.labels.rejectReason')"
            style="
              border: 1px solid var(--mainColor-2);
              border-radius: 10px;
              padding: 10px !important;
            "
          >
          </v-textarea>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="#F44336"
            @click="changeStatus('admin_reject', reject_reason_item_id)"
          >
            {{ $t("confirm") }}
          </v-btn>
          <v-btn color="#1B5E20" @click="reject_reason_model = false">
            {{ $t("back") }}
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

// Start:: Importing Modals
import TextContentModal from "@/components/Modals/TextContentModal.vue";

// End:: Importing Modals

export default {
  name: "UsersReports",

  components: {
    TextContentModal,
  },

  data() {
    return {
      // ========== Breadcrumbs
      items: [
        {
          text: this.$t("breadcrumb.mainPage"),
          disabled: false,
          href: "/",
        },
        {
          text: this.$t("breadcrumb.orders.title"),
          disabled: true,
          href: "",
        },
        // {
        //   text: this.$t("breadcrumb.transaction.products"),
        //   disabled: true,
        //   href: "",
        // },
      ],

      // ========== Statistics
      statisticsItem: {},

      // ========== Top Section
      search: "",

      // ========== dialog Status
      dialogDelete: false,
      itemtoDelete: null,
      reportReasonModalIsOpen: false,
      reportReasonToShow: null,
      model_1: {
        show_model: false,
        model_img_src: "",
        model_text: "",
      },
      model_2: {
        show_model: false,
        text: "",
      },
      // ========== Your Data
      rows: [], // injected in created

      // ========== pagination
      paginations: {
        current_page: 1,
        last_page: 1,
        items_per_page: 20,
      },

      // ========== Loading
      loading: false,

      // reject reason
      reject_reason_model: false,
      reject_reason: null,
      reject_reason_item_id: null,
    };
  },

  computed: {
    ...mapGetters({
      lang: "lang_module/lang",
    }),

    headers() {
      if (this.lang == "ar") {
        return [
          {
            text: "رقم الطلب",
            align: "center",
            value: "order_num",
            sortable: false,
          },
          {
            text: "رقم العميل",
            align: "center",
            value: "client",
            sortable: false,
          },

          {
            text: "اجمالي السعر",
            align: "center",
            value: "total_price",
            sortable: false,
          },
          {
            text: "الحالة",
            align: "center",
            value: `status_trans`,
            sortable: false,
            width: "200",
            padding: "2",
          },
          // {
          //   text: "الحالة",
          //   align: "center",
          //   value: `status`,
          //   sortable: false,
          //   width: "200",
          //   padding: "2",
          // },
          {
            text: "وقت التوصيل",
            align: "center",
            value: "scheduled_at",
            sortable: false,
          },
          {
            text: "حالة الدفع",
            align: "center",
            value: "is_payment_trans",
            sortable: false,
          },

          // {
          //   text: "نوع الطلب",
          //   align: "center",
          //   value: "order_type",
          //   sortable: false,
          // },
          {
            text: "نوع التوصيل",
            align: "center",
            value: "is_pickup_from_branch",
            sortable: false,
          },

          {
            text: "التحكم",
            value: "actions",
            sortable: false,
            align: "center",
          },
        ];
      } else {
        return [
          {
            text: "Order Number",
            align: "center",
            value: "order_num",
            sortable: false,
          },
          {
            text: "Client Number",
            align: "center",
            value: "client",
            sortable: false,
          },

          {
            text: "Total Price",
            align: "center",
            value: "total_price",
            sortable: false,
          },
          {
            text: "Status",
            align: "center",
            value: `status_trans`,
            sortable: false,
            width: "200",
            padding: "2",
          },
          // {
          //   text: "Status",
          //   align: "center",
          //   value: `status`,
          //   sortable: false,
          //   width: "200",
          //   padding: "2",
          // },
          {
            text: "Delivery Time",
            align: "center",
            value: "scheduled_at",
            sortable: false,
          },
          {
            text: "Pay Status",
            align: "center",
            value: "is_payment_trans",
            sortable: false,
          },

          // {
          //   text: "Order Type",
          //   align: "center",
          //   value: "order_type",
          //   sortable: false,
          // },
          {
            text: "Delievery Type",
            align: "center",
            value: "is_pickup_from_branch",
            sortable: false,
          },
          {
            text: "Control",
            value: "actions",
            sortable: false,
            align: "center",
          },
        ];
      }
    },
    orderStatusItems() {
      return [
        {
          label: this.$t("ordersStatus.watingAdmin"),
          name: "pending",
        },
        {
          label: this.$t("ordersStatus.adminAccept"),
          name: "store_accept",
        },
        {
          label: this.$t("ordersStatus.adminCancel"),
          name: "store_reject",
        },
        {
          label: this.$t("ordersStatus.clientCancel"),
          name: "client_cancel",
        },
        {
          label: this.$t("ordersStatus.shipping"),
          name: "admin_shipping",
        },
        {
          label: this.$t("ordersStatus.deliverd"),
          name: "admin_delivered",
        },
        {
          label: this.$t("ordersStatus.clientFinished"),
          name: "client_finished",
        },
      ];
    },
  },

  methods: {
    showAddress(address) {
      this.model_2.show_model = true;
      this.model_2.text = address.location_description;
    },
    // ===== Search Method =====
    filterClick(word) {
      if (!this.loading) {
        this.search = word;
        this.helper_filterSearch();
      }
    },
    openRejectModel(id) {
      this.reject_reason_item_id = id;
      this.reject_reason_model = true;
    },
    changeStatus(currStatus, id) {
      this.btnIsLoading = true;
      let submit_data = new FormData();
      let keepGoing = true;
      if (currStatus == "admin_reject") {
        if (!this.reject_reason) {
          this.$iziToast.error({
            timeout: 2000,
            message: this.$t("forms.validation.reject_reason"),
            position: "bottomRight",
          });
          keepGoing = false;
        }

        submit_data.append("reject_reason", this.reject_reason);
      }
      if (!keepGoing) {
        return;
      }
      submit_data.append("status", currStatus);
      submit_data.append("_method", "PUT");
      this.$axios({
        method: "POST",
        url: `order/${id}/change_status`,
        data: submit_data,
      })
        .then((res) => {
          this.$iziToast.success({
            timeout: 2000,
            message: res.data.message,
            position: "bottomRight",
          });
          // this.$router.push({ path: "/transactions/all-transactions" });
          this.btnIsLoading = false;
          this.reject_reason_model = false;
          this.setRows();
        })
        .catch((err) => {
          if (err.response.data.status == "fail") {
            this.$iziToast.error({
              timeout: 2000,
              message: err.response.data.message,
              position: "bottomRight",
            });
          }

          this.setRows();
          this.btnIsLoading = false;
        });
    },
    show_model_1(e) {
      this.model_1.model_img_src = e;
      this.model_1.show_model = true;
    },
    orderStatusList(status, item) {
      if (item.is_pickup_from_branch) {
        if (status == "pending") {
          return [
            {
              text: this.$t("accept"),
              value: "order_placed",
            },
            { text: this.$t("reject"), value: "admin_reject" },
          ];
        } else if (status == "order_placed") {
          return [
            {
              text: this.$t("progress"),
              value: "progress",
            },
            { text: this.$t("cancel"), value: "admin_cancel" },
          ];
        } else if (status == "progress") {
          return [
            {
              text: this.$t("deliverd"),
              value: "delivered",
            },
            { text: this.$t("cancel"), value: "admin_cancel" },
          ];
        }
      } else {
        if (status == "pending") {
          return [
            {
              text: this.$t("accept"),
              value: "order_placed",
            },
            { text: this.$t("reject"), value: "admin_reject" },
          ];
        } else if (status == "order_placed") {
          return [
            {
              text: this.$t("progress"),
              value: "progress",
            },
            { text: this.$t("cancel"), value: "admin_cancel" },
          ];
        } else if (status == "progress") {
          return [
            {
              text: this.$t("on_the_way"),
              value: "on_the_way",
            },
            { text: this.$t("cancel"), value: "admin_cancel" },
          ];
        }
      }
    },
    // ===== Toggle Report Reason Modal =====
    controlReportReasonModalModal(selectedReportReason) {
      this.reportReasonToShow = selectedReportReason;
      this.reportReasonModalIsOpen = !this.reportReasonModalIsOpen;
    },
    itemRowBackground(item) {
      return item.status == "progress" ? "in_progress" : item.status;
      // console.log(item);
    },

    // ==================== Start CRUD ====================
    addItem() {
      this.$router.push({ path: "/orders/add" });
    },
    showItem(item) {
      this.$router.push({ path: "/orders/show/" + item.id });
    },
    editItem(item) {
      this.$router.push({ path: "/orders/edit/" + item.id });
    },
    // ===== Delete
    deleteItem(item) {
      this.dialogDelete = true;
      this.itemtoDelete = item;
    },
    deleteItemConfirm() {
      this.$axios({
        method: "DELETE",
        url: `order/${this.itemtoDelete.id}`,
      })
        .then(() => {
          this.rows = this.rows.filter((item) => {
            return item.id != this.itemtoDelete.id;
          });
          this.dialogDelete = false;
          this.$iziToast.success({
            timeout: 2000,
            message: this.$t("deleteSuccess"),
            position: "bottomRight",
          });
        })
        .catch((err) => {
          this.dialogDelete = false;
          this.$iziToast.error({
            timeout: 2000,
            message: err.response.data.message,
            position: "bottomRight",
          });
        });
    },
    // ==================== End CRUD ====================

    // Set Rows
    setRows(e) {
      this.loading = true;
      this.$axios({
        method: "GET",
        url: "order",
        params: {
          page: this.paginations.current_page,
          order_id: e?.order_id || "",
          customer_number: e?.customer_number || "",
          branch_name: e?.branch_number || "",
          is_pickup: e?.delievery_type ? e?.delievery_type.value : "",
          // from: e?.dateStart ? e.dateStart : "",
          // to: e?.dateEnd ? e.dateEnd : "",
          status: e?.status ? e.status.name : "",
          // status: e?.status ? e.status.name : "",
          // is_payment: e?.paidStatus ? e.paidStatus.name : "",
          // pay_type: e?.payment ? e.payment.name : "",
        },
      })
        .then((res) => {
          this.paginations.last_page = res.data.meta.last_page;
          this.paginations.items_per_page = res.data.meta.per_page;

          this.rows = res.data.data;

          this.statisticsItem.number = res.data.meta.total;

          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    fetchData(e) {
      this.$router.replace({ query: { page: e } });
      this.setRows();
    },
  },
  created() {
    if (this.$route.query.page) {
      this.paginations.current_page = +this.$route.query.page;
    }
    this.setRows();
  },

  // ======= hooks
};
</script>

<style lang="scss">
.v-text-field__details {
  display: none !important;
}
.v-input__slot {
  margin-bottom: 0 !important;
}
.status_btn {
  i {
    padding: 0.5rem;
    color: white;
    font-size: 1.2rem;
    border-radius: 50%;
    margin-bottom: 5px;
    transition: all 0.3s;
  }
  &.accept {
    i {
      background: #1b5e20;
    }
  }
  &.reject {
    i {
      padding: 0.5rem 0.75rem;
      background: #f44336;
    }
  }
  &:hover {
    i {
      scale: 1.2;
      opacity: 0.8;
    }
  }
}
</style>
